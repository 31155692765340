<template>
    <div class="checkout-wrapper">

     
        <a href="senseye://">RETOURNER SUR VISAGES</a>
       
      
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
     </div>
</template>

<script>


export default {
  name: 'RedirectApp',
  components: {
    
  },
  data() {
    return {
       
    }
  },
  props:["displayModalMenu","language"],
  methods:{
    
  },
  async mounted()
  {
   
     
  }
  
}
</script>
<style>

</style>